.question-page {
    text-align: center;
    color: #d87fa0;
}

.questionnaire-page {
    text-align: center;
    color: #01a3e0;
}

.question {
    color: #01a3e0;
}

.fp-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #cccacaad;
}

.fp-container .fp-loader {
    top: 30%;
    left: 48%;
    z-index: 1000;
    position: absolute;
}

.next-prev-font-size {
    font-size: 0.7em;
}